.container {
  min-width: 299px;
  margin: 0 0 0.25cm;
  border: none;
  border-collapse: collapse;
  font-size: 9pt;

  th {
    text-align: right;
  }

  th, td {
    padding: 5px;
    border: 1px solid #666;
  }

  .single {
    .idx {
      width: 8%;
    }

    .name {
      width: 34%;
    }

    .note {
      width: 58%;
    }
  }

  .double {
    .idx {
      width: 4%;
    }

    .name {
      width: 20%;
    }

    .note {
      width: 26%;
    }
  }
}
