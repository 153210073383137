import React, { createContext, useContext } from 'react';
import { PlainObject } from './types';
import y2022 from '../etc/config.2022.yml';
import y2023 from '../etc/config.2023.yml';
import krest from '../etc/config.krest.yml';
import defaultData from '../etc/config.yml';

if (module.hot) {
  module.hot.dispose(() => location.reload());
}

const data = {
  '/2022': y2022,
  '/2023': y2023,
  '/krest': krest,
}[location.pathname] ?? defaultData;

const Ctx = createContext<PlainObject>(undefined as any);

export function useRawData(): PlainObject {
  return useContext(Ctx);
}

export type DataLoaderProps = {
  children?: React.ReactNode;
};

export const DataLoader: React.FC<DataLoaderProps> = ({ children }) => {
  return (
    <Ctx.Provider value={data}>
      {children}
    </Ctx.Provider>
  );
};
